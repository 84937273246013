@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratRegular";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

body {
  font-family: MontserratRegular !important;
}

.main-box {
  height: calc(100vh - 56px);
}

.qrbox-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.qrcode-shadow {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.navbar-shadow {
  box-shadow: 0px 4px 4px 0px #00000017;
}

.box-shadow {
  box-shadow: 0px 3px 25px 0px #7777777d;
}

.type-box-shadow {
  box-shadow: 0px 2px 8px 0px #8b8989a6;
}

.box-inset-shadow {
  box-shadow: 5px 5px 5px 0px #74747440 inset;
}

.ant-dropdown-menu-item {
  color: #385294 !important;
}

.inputBox-shadow {
  box-shadow: 0px 1px 3px 0px #00000040;
}

.exp-btnShadow {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.ant-picker-ok button {
  background-color: #2965ff;
}

.thin-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: #2965ff;
  border: 2px solid #555555;
  border-radius: 10px;
}

.preview-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.preview-scroll::-webkit-scrollbar-thumb {
  background-color: #002b38;
  border: 2px solid #555555;
  border-radius: 10px;
}

.theme-color-picker {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border: none;
  outline: none;
  padding: 0 !important;
}

.theme-color-picker .ant-color-picker-color-block {
  box-shadow: 0px 1px 10px 0px #00000040;
  margin-left: 6px;
}

.ant-color-picker-trigger-text {
  height: 32px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 10px 0px #00000040;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;
  width: 120px;
  margin: 0 !important;
  text-indent: 5px;
}

.flipCard {
  transform-style: preserve-3d;
  transition: all 2s ease;
}

.flipCard .front {
  backface-visibility: hidden;
}

.flipCard .back {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.flipCard-active {
  transform: rotateY(180deg);
}

.preview-dropbox {
  box-shadow: 0px 2px 2px 0px #00000040;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #2965ff !important;
  color: white;
  text-align: center;
  font-size: 16px;
}

.login-gradiant {
  background: linear-gradient(180deg, #2965ff 0%, #0f255c 100%);
}

.selectBox div {
  height: 3rem !important;
}

.filterSelectBox div {
  height: 2.2rem !important;
}

.filterSelectBox div span input {
  height: 2.2rem !important;
}

.selectBox div span {
  display: flex !important;
  align-items: center !important;
}

.selectBox div span input {
  height: 3rem !important;
}

.selectBox .ant-select-selector {
  box-shadow: 0px 1px 3px 0px #00000040 !important;
}

.selectBox {
  border: none;
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-select .ant-select-arrow {
  top: 80% !important;
}

.sectionSelectBox div {
  height: 3.5rem !important;
}

.sectionSelectBox .ant-select-arrow {
  top: 85% !important;
}

/* .themeRating span {
  margin-right: 2px;
  margin-left: 2px;
} */

.ant-slider-rail {
  background-color: #d1c7c7 !important;
}

.ant-slider-track {
  background-color: #2965ff !important;
}

.ant-slider-handle::after {
  background-color: #2965ff !important;
  box-shadow: none !important;
  width: 20px !important;
  height: 20px !important;
  top: -5px !important;
}

.phone-input input {
  height: 2rem !important;
  width: 100% !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 8px !important;
}

.phone-input input:focus {
  border: none !important;
}

.submitModalSelectBox div {
  height: 2.75rem !important;
}

.submitModalSelectBox div span input {
  height: 2.75rem !important;
}

:where(.css-dev-only-do-not-override-18hwu31).submitModalSelectBox
  .ant-select-arrow {
  bottom: 20% !important;
  padding-top: 10px;
}

.loader-white .ant-spin-dot .ant-spin-dot-item {
  background-color: #ffffff !important;
}

.ant-menu-item-selected {
  background-color: #2965ff !important;
}

.ant-menu-item {
  /* margin-right: 8px !important; */
  /* margin-left: 8px !important; */
  width: 86% !important;
  height: 40px !important;
  @apply !rounded;
}

.ant-menu {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.setup-container {
  background: url("./assets/images/verification-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-anchor-link-active {
  @apply bg-themeGray-dark;
}

.actionButton-hide {
  display: flex !important;
}

@media only screen and (max-width: 1050px) {
  .actionButton-hide {
    @apply !hidden;
  }
  .actionButton-display {
    display: flex !important;
  }
}

.sidemenu-drawer .ant-drawer-body {
  padding: 0 !important;
}
